import { useEffect, useState } from "react";
import { db } from "../components/firebase.js";
import BgShape from "../images/hero/bookbg.jpg";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,
  addDoc, query, where
} from "firebase/firestore";
import PhoneInput from "react-phone-number-input";
import "./numberinput.css"; // Import your custom styles
import emailjs from "@emailjs/browser";


function BookCar({ carName }) {
  const [carTypes, setCarTypes] = useState([]);
  const [selectedCar, setSelectedCar] = useState(carName);
  const [carImgUrl, setCarImgUrl] = useState("");
  const [carData, setCarData] = useState([]);

  useEffect(() => {
    setCarImgUrl("");
  }, [carName]);

  useEffect(() => {
    console.log(carName);
    setSelectedCar(carName);
    setCarData("");

    const handleSelectChange = async () => {
      // Find the selected car in the data and set its image URL
      const selectedCarData = carData.find((car) => car.name === carName);
      if (selectedCarData) {
        setCarImgUrl(selectedCarData.img);
      } else {
        // If the data is not in the state, fetch it from Firestore
        try {
          const querySnapshot = await getDocs(collection(db, "carsdata"));
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setCarData(data);
          const newSelectedCarData = data.find((car) => car.name === carName);
          if (newSelectedCarData) {
            setCarImgUrl(newSelectedCarData.img);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    handleSelectChange();

    console.log("Car Changed");
  }, [carName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCarData(data); // Update this line
        setCarTypes(data.map((car) => car.name));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [carName]);

  const handleSelectChange = async (event) => {
    const selectedCarName = event.target.value;
    setSelectedCar(selectedCarName);

    // Find the selected car in the data and set its image URL
    const selectedCarData = carData.find((car) => car.name === selectedCarName);
    if (selectedCarData) {
      setCarImgUrl(selectedCarData.img);
    } else {
      // If the data is not in the state, fetch it from Firestore
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCarData(data);
        const newSelectedCarData = data.find(
          (car) => car.name === selectedCarName
        );
        if (newSelectedCarData) {
          setCarImgUrl(newSelectedCarData.img);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  //////////////

  const [modal, setModal] = useState(false); //  class - active-modal
  // booking car
  const [carType, setCarType] = useState("");
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickTime, setPickTime] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [carImg, setCarImg] = useState("");
  // modal infos
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  //const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipCode] = useState("");

  const [pickingTime, setPickingTime] = useState("");
  const [droppingTime, setDroppingTime] = useState("");

  // taking value of modal inputs
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleAge = (e) => {
    setAge(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleZip = (e) => {
    setZipCode(e.target.value);
  };

  // open modal when all inputs are fulfilled
  const openModal = (e) => {
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");

PriceCalc()

    if (
      !pickUp ||
      !dropOff ||
      !pickTime ||
      !dropTime ||
      !droppingTime ||
      !selectedCar ||
      !pickingTime
    ) {
      errorMsg.style.display = "flex";
    } else {
      setModal(!modal);
      const modalDiv = document.querySelector(".booking-modal");
      modalDiv.scroll(0, 0);
      errorMsg.style.display = "none";
    }
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  // confirm modal booking

  const handleTimeChange = (e) => {
    setPickingTime(e.target.value);
  };

  const handleTimeChange2 = (e) => {
    setDroppingTime(e.target.value);
  };

  const convertTo12HourFormat = (timeString) => {
    const [hour, minute] = timeString.split(":");
    const parsedHour = parseInt(hour, 10);
    const ampm = parsedHour >= 12 ? "PM" : "AM";
    const formattedHour = parsedHour % 12 === 0 ? 12 : parsedHour % 12;
    return `${formattedHour}:${minute} ${ampm}`;
  };

  const [price, setPrice] = useState(0);
  const [dailyrate, setDailyRate] = useState(0);

  
  useEffect(() => {
    const fetchDailyRate = async () => {
      try {
        if (selectedCar) {
          // Query Firestore to find the document with the matching name
          const q = query(collection(db, "carsdata"), where("name", "==", selectedCar));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0].data();
            setDailyRate(docData.price); // Set the dailyRate state to the price field value
          } else {
            console.error("No matching document found.");
          }
        }
      } catch (error) {
        console.error("Error fetching daily rate:", error);
      }
    };

    fetchDailyRate();
  }, [selectedCar]);


const PriceCalc = async () => {

    let pickTimez = new Date(pickTime);
    let dropTimez = new Date(dropTime);

    // Check if the conversion was successful
    if (isNaN(pickTimez.getTime()) || isNaN(dropTimez.getTime())) {
      console.error("Invalid date format");
      return;
    }

    // Calculate the time difference in milliseconds
    let timeDifference = dropTimez.getTime() - pickTimez.getTime();

    // Convert time difference from milliseconds to days
    let dayDifference = timeDifference / (1000 * 3600 * 24);
    let totdif = dayDifference

    let abc = 0 ;
    let xyz = 0 ;
    
    if (pickUp === 'Our-Office') {
      abc = 0;
    } else if (pickUp === 'Hatchet-Bay-Dock') {
      abc = 0;
    } else if (pickUp === 'ELH') {
      abc = 50;
    } else if (pickUp === 'GHB') {
      abc = 25;
    } else if (pickUp === 'RSD') {
      abc = 70;
    } 

    if (dropOff === 'Our-Office') {
      xyz = 0;
    } else if (dropOff === 'Hatchet-Bay-Dock') {
      xyz = 0;
    } else if (dropOff === 'ELH') {
      xyz = 50;
    } else if (dropOff === 'GHB') {
      xyz = 25;
    } else if (dropOff === 'RSD') {
      xyz = 70;
    } 

    const newPrice = dailyrate * totdif + abc + xyz
     setPrice(newPrice);
  };


  const sendEmail = () => {

    const templateParams = {
      type: 'Booking',
    };
  
    emailjs
      .send('service_cogayf6', 'template_anduy23', templateParams, 'undyecVjIXyqVGZXU')
      .then(
        (response) => {
          console.log('Email sent successfully:', response.status, response.text);
        },
        (error) => {
          console.error('Failed to send message:', error.text);
        }
      );
  };





  const postBooking = async () => {
    try {
      // Condition to validate Booking Form Fields

      if (!name || !lastName || !age || !phone || !email || !city)
        window.alert("Error: Fill All Fields!");
      else {
        const formattedTime = convertTo12HourFormat(pickingTime);
        const formattedTime1 = convertTo12HourFormat(droppingTime);

        sendEmail()
        // const pickupFieldValue = `${formattedTime} ${pickTime}`;

        await db.collection("bookingsdata").add({
          carname: selectedCar,
          pickup: pickUp,
          dropoff: dropOff,
          picktime: pickTime,
          droptime: dropTime,
          pickingtime: formattedTime,
          droppingtime: formattedTime1,
          name: `${name} ${lastName}`,
          phone: phone,
          age: age,
          email: email,
          city: city,
          zip: price,
        });

        
        // Log a message to the console if the document was added successfully
        console.log("Booking Details Posted!");

        // Show an alert to the user
        window.alert("Your booking is recieved! Please keep a check on your email & spam box for our email with a subject 'Dell's Rent A Car, Eleuthera Bahamas'");

        // Close the modal
        setModal(false);

        // Display the "booking done" message
        const doneMsg = document.querySelector(".booking-done");
        doneMsg.style.display = "flex";

        // Clear the form fields
        setPickUp("");
        setDropOff("");
        setPickTime("");
        setDropTime("");
        setCarType("");
        setName("");
        setLastName("");
        setPhone("");
        setAge("");
        setEmail("");
        setCity("");
        setPickingTime("");
        setDroppingTime("");
        setZipCode("");
      }
    } catch (error) {
      // Log any errors to the console
      console.error(error);
    }
  };

  const confirmBooking = (e) => {
    e.preventDefault();
    postBooking();
  };

  // taking value of booking inputs
  const handleCar = (e) => {
    const selectedCarName = e.target.value;
    setCarType(selectedCarName);

    // Find the selected car in the data and set its image URL
    const selectedCarData = carData.find((car) => car.name === selectedCarName);
    if (selectedCarData) {
      setCarImg(selectedCarData.img);
    }
  };

  const handlePick = (e) => {
    setPickUp(e.target.value);
  };

  const handleDrop = (e) => {
    setDropOff(e.target.value);
  };

  const handlePickTime = (e) => {
    setPickTime(e.target.value);
  };

  const handleDropTime = (e) => {
    setDropTime(e.target.value);
  };

  const formattedTimes = convertTo12HourFormat(pickingTime);
  const formattedTimes1 = convertTo12HourFormat(droppingTime);
  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };

  const bgImageStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };

  return (
    <>
      <section
        id="booking-section"
        className="book-section"
        style={{ position: "relative" }}
      >
        {/* overlay */}
        <img src={BgShape} alt="Background Shape" style={bgImageStyle} />
        <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>

        <div className="plan-container__title" style={{ paddingTop: "30px" }}>
          <h2>Reserve Your Car</h2>
        </div>

        <div className="container">
          <div className="book-content">
            <div
              className="book-content__box"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.45)", // 20% opacity for the background color
                // backgroundImage: 'linear-gradient(315deg, rgba(0, 153, 14, 0.2) 0%, rgba(77, 212, 18, 0.2) 74%)', // 20% opacity for the gradient
              }}
            >
              <h4
                style={{
                  color: "white",
                  textAlign: "center",
                  marginBottom: "5px",
                }}
              >
                You can also message us on whatsapp to make a booking. Click{" "}
                <a
                  href="https://wa.me/12425510538"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Here
                </a>
              </h4>

              <h2 style={{ color: "yellow", fontWeight: "bold" }}>
                Book a car{" "}
              </h2>
              <p className="error-message">
                All fields required! <i className="fa-solid fa-xmark"></i>
              </p>
              <p className="booking-done">
                Your booking is saved! Please do re-confirm it at over call
                before arrival.{" "}
                <i onClick={hideMessage} className="fa-solid fa-xmark"></i>
              </p>
              <form className="box-form">
                <div className="box-form__car-type">
                  <label style={{ color: "yellow" }}>
                    <i className="fa-solid fa-car"></i> &nbsp; Select Your Car
                    Type <b>*</b>
                  </label>

                  <select
                    value={selectedCar}
                    onChange={handleSelectChange}
                    style={{ color: "#444443" }}
                  >
                    <option>Select your car </option>
                    {carTypes.map((carType, index) => (
                      <option key={index} value={carType}>
                        {carType}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="box-form__car-type">
                  <label style={{ color: "yellow" }}>
                    <i className="fa-solid fa-location-dot"></i> Pick-up{" "}
                    <b>*</b>
                  </label>
                  <select
                    value={pickUp}
                    onChange={handlePick}
                    style={{ color: "#444443" }}
                  >
                    <option>Select pick up location</option>
                    <option>Our Office</option>
                    <option>Hatchet Bay Dock</option>
                    <option>ELH</option>
                    <option>GHB</option>
                    <option>RSD</option>
                  </select>
                </div>
                <div className="box-form__car-type">
                  <label style={{ color: "yellow" }}>
                    <i className="fa-solid fa-location-dot"></i> Drop-off{" "}
                    <b>*</b>
                  </label>
                  <select
                    value={dropOff}
                    onChange={handleDrop}
                    style={{ color: "#444443" }}
                  >
                    <option>Select drop off location</option>
                    <option>Our Office</option>
                    <option>Hatchet-Bay-Dock</option>
                    <option>ELH</option>
                    <option>GHB</option>
                    <option>RSD</option>
                  </select>
                </div>

                <div className="box-form__car-time">
                  <label htmlFor="picktime" style={{ color: "yellow" }}>
                    <i className="fa-regular fa-calendar-days "></i>
                    Pick-up Date <b>*</b>
                  </label>
                  <input
                    id="picktime"
                    value={pickTime}
                    onChange={handlePickTime}
                    type="date"
                  ></input>

                  <label
                    htmlFor="droptime"
                    style={{ marginTop: "13px", color: "yellow" }}
                  >
                    Pick-up Time <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    value={pickingTime}
                    onChange={handleTimeChange}
                    type="time"
                    className="input-time"
                    style={{ width: "150px" }}
                  />
                </div>

                <div className="box-form__car-time">
                  <label htmlFor="droptime" style={{ color: "yellow" }}>
                    <i className="fa-regular fa-calendar-days "></i>
                    Drop-of Date <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    value={dropTime}
                    onChange={handleDropTime}
                    type="date"
                  ></input>

                  <label
                    htmlFor="droptime"
                    style={{ marginTop: "13px", color: "yellow" }}
                  >
                    Drop-off Time <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    value={droppingTime}
                    onChange={handleTimeChange2}
                    type="time"
                    className="input-time"
                    style={{ width: "150px" }}
                  />
                </div>
              </form>

              <button
                onClick={openModal}
                type="submit"
                style={{
                  alignSelf: "center",
                  width: "260px",
                  marginTop: "14px",
                  backgroundColor: "#182b3a",
                  backgroundImage:
                    "linear-gradient(0deg, #182b3a 0%, #20a4f3 90%)",
                  //backgroundImage: 'linear-gradient(275deg, #00690a 0%, #3a9e0e 79%)',
                  //transition: "box-shadow 0.3s ease-in-out",

                  borderRadius: "33px",
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* modal ------------------------------------ */}
      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        <div
          className="booking-modal__title"
          style={{
            backgroundColor: "#5078f2",
            backgroundImage: "linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)",
          }}
        >
          <h2>Complete Reservation</h2>
          <i onClick={openModal} className="fa-solid fa-xmark"></i>
        </div>
        {/* message */}
        <div className="booking-modal__message">
          <h4 style={{ color: "blue" }}>
            <i className="fa-solid fa-circle-info"></i> Upon completing this
            reservation enquiry:
          </h4>
          <p>
            Our staff will contact you via Email. <span style={{fontWeight:'bold', color:'black'}}>Please keep a check on your email & spam box for our email with a subject 'Dell's Rent A Car, Eleuthera Bahamas'</span>
          </p>
        </div>
        {/* car info */}
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5 style={{ color: "blue" }}>Location & Date</h5>
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Pick-Up Date & Time</h6>
                  <p>
                    {pickTime} / {formattedTimes}
                  </p>
                </div>
              </span>
            </div>
            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Drop-Off Date & Time</h6>
                  <p>
                    {dropTime} / {formattedTimes1}
                  </p>
                </div>
              </span>
            </div>
            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Pick-Up Location</h6>
                  <p>{pickUp}</p>
                </div>
              </span>
            </div>
            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Drop-Off Location</h6>
                  <p>{dropOff}</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5 style={{ color: "blue" }}>
              <span>Car -</span> {selectedCar}
            </h5>
            {carImgUrl && <img src={carImgUrl} alt="car_img" />}
          </div>
        </div>

<div>

<h2 style={{textAlign:'center', padding:'15px', fontWeight:'bold'}}>Total Approximated Price: <span style={{color:'green'}}>{price} USD</span> </h2> 

<h3 style={{textAlign:'center', padding:'15px'}}><span style={{color:'grey'}}>The approximated price above includes the basic car rental price and Pickup & Dropoff service charges. This price can have slight variance.</span> </h3> 

</div>


        {/* personal info */}
        <div className="booking-modal__person-info" style={{}}>
          <h4 style={{ color: "blue" }}>Personal Information</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  First Name <b>*</b>
                </label>
                <input
                  value={name}
                  onChange={handleName}
                  type="text"
                  placeholder="Enter your first name"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Last Name <b>*</b>
                </label>
                <input
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your last name"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <div className="phoneInputContainer">
                  <label className="label">
                    Phone Number <b>*</b>
                  </label>
                  <div className="phoneInputWrapper">
                    <PhoneInput
                      international
                      defaultCountry="US"
                      value={phone}
                      onChange={setPhone}
                      placeholder="Enter your phone number"
                      className="phoneInput"
                    />
                  </div>
                  <p className="error-modal">This field is required.</p>
                </div>
              </span>

              <span>
                <label>
                  Age <b>*</b>
                </label>
                <input
                  value={age}
                  onChange={handleAge}
                  type="number"
                  placeholder="Enter Age"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>
            <div className="info-form__1col">
              <span>
                <label>
                  Email <b>*</b>
                </label>
                <input
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              {/*
              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                  value={address}
                  onChange={handleAddress}
                  type="text"
                  placeholder="Enter your street address"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
              */}
            </div>
            <div className="info-form__2col">
              <span>
                <label>
                  Country <b>*</b>
                </label>
                <input
                  value={city}
                  onChange={handleCity}
                  type="text"
                  placeholder="Enter your country"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>
            </div>
            <div className="reserve-button">
              <button
                onClick={confirmBooking}
                style={{
                  backgroundColor: "#182b3a",
                  backgroundImage:
                    "linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)",
                  borderColor: "blue",
                }}
              >
                Reserve Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BookCar;
